import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
import { Link } from "react-router-dom";

import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";

import Button from "../Components/Button";
import Column from "../Components/Column";
import Wrapper from "../Components/Wrapper";
import Modal from "../Components/Modal";
import Loader from "../Components/Loader";
import ModalResult from "../Components/ModalResult";

import { apiGetAccountAssets } from "../helpers/api";
import {
  formatTestTransaction,
  getChainData
} from "../helpers/utilities";
import { IAssetData } from "../helpers/types";
import { fonts } from "../styles";
import {
  ETH_SEND_TRANSACTION,
} from "../constants";
import { DAI_CONTRACT } from '../constants/';
import '../css/theme.css';
import { Helmet } from "react-helmet";

const SLayout = styled.div`
  position: relative;
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SLanding = styled(Column)`
  height: 600px;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled(Button)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
`;

interface IAppState {
  fetching: boolean;
  days: number;
  hours: number;
  min: number;
  sec: number;
  range : number;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  sold : number
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  days: 0,
  hours: 0,
  min: 0,
  sec: 0,
  range: 1,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  sold: 0,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });
  }

  public async componentDidMount() {
    await this.getdata();
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
  }

  public onConnect = async () => {
    const provider = await this.web3Modal.connect();

    await this.subscribeProvider(provider);

    const web3: any = initWeb3(provider);

    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();

    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId
    });
    await this.getAccountAssets();
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await this.setState({ address: accounts[0] });
      await this.getAccountAssets();
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID
        }
      }
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public testSendTransaction = async () => {
    const { web3, address, chainId } = this.state;

    if (!web3) {
      alert("Connect your Wallet");
      return;
    }

    // const tx = await formatTestTransaction(address, chainId);

    const contract : any = await new web3.eth.Contract(DAI_CONTRACT[1].abi , DAI_CONTRACT[1].address)
    let mcount : any = (this.state.range * 8e16).toString();
    var data : any = contract.methods.mint(
      web3.utils.toHex(this.state.range),
    );
    var rawTransaction = {
      from: address,
      to: DAI_CONTRACT[1].address,
      value: web3.utils.toHex(mcount),
      data: data.encodeABI(),
      maxPriorityFeePerGas: null,
      maxFeePerGas: null, 
    };

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // @ts-ignore
      function sendTransaction(_tx: any) {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => resolve(txHash))
            .catch((err: any) => reject(err));
        });
      }

      // send transaction
      const result = await sendTransaction(rawTransaction);

      // format displayed result
      const formattedResult = {
        action: ETH_SEND_TRANSACTION,
        txHash: result,
        from: address,
        to: address,
        value: "0 ETH"
      };
      this.toggleModal();
      // display result
      // this.setState({
      //   web3,
      //   pendingRequest: false,
      //   result: formattedResult || null
      // });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
      this.toggleModal();
    }
  };

  public getdata = async () => {
    let web3 : any = new Web3();
    web3.setProvider(new web3.providers.HttpProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`));
    const contract : any = new web3.eth.Contract(DAI_CONTRACT[1].abi, DAI_CONTRACT[1].address);
    var data : any = await contract.methods.totalSupply().call();
    this.setState({ sold: data });
  };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };
  public add = () => {
    if(this.state.range < 20){
      this.setState({ range: this.state.range+1 });
    }
  };
  public sub = () => {
    if(this.state.range > 1){
      this.setState({ range: this.state.range-1 });
    }
  };

  public render = () => {
    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      pendingRequest,
      result
    } = this.state;
    return (
      <SLayout>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="icon" href="/Images1/inner-logo.PNG" />
          <meta
            name="description"
            content="Chill Cubes"
          />
          <title>Chill Cubes</title>
        </Helmet>
        <div className="chillCubes">
          <div className="container ">
            <div className="connect">
              <div className="connect_topbar d-block text-center d-lg-flex mb-5 justify-content-between align-items-center">
                <img alt="zedheads" src="Images/logo.png"  width="260px" />
                <div className="text_right text-center pt-lg-0 pt-4 d-block d-lg-flex align-items-center">
                  {!this.state.address ? (
                    <>
                      <button
                        className="btn_connect"
                        onClick={this.onConnect}
                      >
                        Connect Wallet
                      </button>
                    </>
                  ) : (
                    <div className="d-flex align-items-center">
                      <p className="me-4 mb-0 text-white account">{this.state.address}</p>
                      <button
                        className="btn_disconnect"
                        onClick={this.resetApp}
                      >
                        Disconnect Wallet
                      </button>
                    </div>
                  )}
                </div>
              </div> 
              <div className="container-fluid">  
                <div className="row justify-content-around align-items-center"> 
                <div className="col-lg-5 col-md-5 col-12 py-4 order-lg-1 order-5 text-center">
                    <div className="py-4 d-flex justify-content-center align-items-center">
                      <button className="chnage_btn" onClick={this.sub}>
                        <img alt="zedheads" src="Images/minusbutton.png" className="button_count"  />
                      </button>
                      <div className="px-4"><div className="count d-flex justify-content-center align-items-center">{this.state.range}</div></div>
                      <button className="chnage_btn" onClick={this.add}>
                        <img alt="zedheads" src="Images/plusbutton.png" className="button_count" />
                      </button>
                    </div>
                    <button className="mint_btn" onClick={this.testSendTransaction}><b>MINT <br />NOW</b></button>
                    {/* <button className="mint_btn" ><b>MINT <br />NOW</b></button> */}
                    <p className="mb-2 text-white">{this.state.sold} of 7777 NFT's</p>
                    <a className="connectbtn_t002 mb-4 mt-4 " href="https://opensea.io/collection/chillcubes" target="_blank">
                      <img alt="opensea" src="Images/opensea.png" width="170px" />
                      </a>
                    <p className="mint_detail text-left pt-5">
                        # 0.08 ETH each <br/>
                        # ERC-721 tokens<br/>
                        # minted on Ethereum Mainnet<br/>
                        # metadata stored on IPFS<br/>
                      </p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-12 py-4 order-lg-5 order-1 text-center">
                    <img alt="zedheads" src="Images/pic.png" className="border_radius" width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Modal show={showModal} toggleModal={this.toggleModal}>
          {pendingRequest ? (
            <SModalContainer>
              <SModalTitle>{"Pending Call Request"}</SModalTitle>
              <SContainer>
                <Loader />
                <SModalParagraph>
                  {"Approve or reject request using your wallet"}
                </SModalParagraph>
              </SContainer>
            </SModalContainer>
          ) : result ? (
            <SModalContainer>
              <SModalTitle>{"Call Request Approved"}</SModalTitle>
              <ModalResult>{result}</ModalResult>
            </SModalContainer>
          ) : (
            <SModalContainer>
              <SModalTitle>{"Call Request Rejected"}</SModalTitle>
            </SModalContainer>
          )}
        </Modal>
      </SLayout>
    );
  };
}

export default App;
