import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import { Route, Switch } from "react-router-dom";
import Minting from "./Pages/Minting";
// import Her from "./Pages/HerNft";
// import Claim from "./Pages/Claim";
import Presale from "./Pages/Presale";
import Home from "./Pages/Home";
import Sappy from "./Pages/Sappy";

function App() {
  return (
    <Switch>
        {/* <Route  exact path="/">
          <Sappy />
        </Route> */}
        <Route exact path="/">
          <Minting />
        </Route>
        {/* <Route  exact path="/hernft">
          <Her />
        </Route> */}
        {/* <Route  exact path="/sappy">
          <Sappy />
        </Route> */}
    </Switch>
  );
}

export default App;
